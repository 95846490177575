<template>
  <div class="px-2 py-2" color="white">
    <IVBand />
  </div>
</template>

<script>
import IVBand from '@/components/apps/market-information/iv-band/index.vue'

export default {
  name: 'iv-band',

  meta: {
    title: 'IVBand',
  },

  components: {
    IVBand,
  },
}
</script>
