module.exports = {
  chart: {
    height: 700,
  },
  legend: {
    enabled: true,
  },
  rangeSelector: {
    enabled: false,
  },
  title: {
    text: 'BTCUSDT',
  },
  xAxis: {
    plotBands: [],
    min: Date.now() - 7 * 86400e3,
    type: 'datetime',
  },
  yAxis: [
    {
      height: '5%', // ???
    },
    {
      top: '1%',
      height: '99%',
    },
  ],
  plotOptions: {
    candlestick: {
      color: '#E74C3C',
      upColor: '#2ECD71',
    },
  },
  tooltip: {
    valueDecimals: 2,
    valuePrefix: '$',
    valueSuffix: ' USD',
  },
  series: [
    {
      name: 'Bitcoin Price',
      type: 'candlestick',
      data: [],
      yAxis: 1,
    },
    {
      name: 'Upper band',
      color: '#ccc',
      type: 'arearange',
      data: [],
      yAxis: 1,
    },
    {
      name: 'Lowwer band',
      color: '#ccc',
      type: 'arearange',
      data: [],
      yAxis: 1,
    },
  ],
}
