<template>
  <div v-if="!loading">
    <div>
      <div
        class="w-full flex md:justify-between justify-end items-center space-x-8 md:text-base text-xs"
      >
        <span class="md:block hidden">
          BTCUSD: {{ price.toLocaleString() }}
        </span>
        <span>
          Updated@
          {{ new Date(timestamp + 1200e3).toLocaleString().replace(':00', '') }}
        </span>
      </div>
      <div class="text-xs" v-if="isMobile">
        <div>Bitvol: {{ bitvol }}</div>
        <div>
          <span>【Day】:</span>
          <span>${{ minDay.toLocaleString() }}</span>
          <span>~</span>
          <span>${{ maxDay.toLocaleString() }}</span>
        </div>
        <div>
          <span>【Week】:</span>
          <span>${{ minWeek.toLocaleString() }}</span>
          <span>~</span>
          <span>${{ maxWeek.toLocaleString() }}</span>
        </div>
      </div>
      <div class="flex-start space-x-8" v-else>
        <span> Bitvol: {{ bitvol }} </span>
        <span>
          <span>【Day】:</span>
          <span>${{ minDay.toLocaleString() }}</span>
          <span>~</span>
          <span>${{ maxDay.toLocaleString() }}</span>
        </span>
        <span>
          <span>【Week】:</span>
          <span>${{ minWeek.toLocaleString() }}</span>
          <span>~</span>
          <span>${{ maxWeek.toLocaleString() }}</span>
        </span>
      </div>
    </div>
    <chart :options="options" ref="chart" constructor-type="stockChart" />
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep'
import { Chart } from 'highcharts-vue'
import { mapGetters } from 'vuex'
import chartOptions from './chartOptions.js'

const ONE_HOUR = 3600e3
const ONE_DAY = 24 * ONE_HOUR
const SPAN = 8 * ONE_HOUR

export default {
  name: 'IVBand',

  components: {
    Chart,
    // LxvxInfo,
  },

  data: () => ({
    options: null,
    data: [],
    timestamp: 0,
    bitvol: 0,
    maxDay: 0,
    minDay: 0,
    maxWeek: 0,
    minWeek: 0,
    loading: true,
    isMobile: global.IS_MOBILE,
  }),

  computed: {
    ...mapGetters({
      price: 'market/btcusd',
    }),
  },

  async created() {
    this.loading = true
    await this.fetch()
    ;[
      this.timestamp,
      ,
      ,
      ,
      ,
      this.maxDay,
      this.minDay,
      this.maxWeek,
      this.minWeek,
      this.bitvol,
    ] = this.data[0]
    if (!this.maxDay) {
      ;[
        this.timestamp,
        ,
        ,
        ,
        ,
        this.maxDay,
        this.minDay,
        this.maxWeek,
        this.minWeek,
        this.bitvol,
      ] = this.data[1]
    }
    this.setData()
    this.loading = false
  },

  methods: {
    async fetch() {
      this.data = await this.$http.get('/api/v2/markets/bitvol')
    },
    setData() {
      let timestamp = Date.now()
      let start = timestamp
      let jp =
        timestamp -
        (timestamp % ONE_DAY) +
        7 * ONE_HOUR -
        9 * ONE_HOUR +
        ONE_DAY

      this.options = clonedeep(chartOptions)
      this.data
        .sort((a, b) => a[0] - b[0])
        .forEach(
          ([
            ts,
            o,
            h,
            l,
            c,
            maxDay,
            minDay,
            maxWeek,
            minWeek,
            // bitvol,
          ]) => {
            start = Math.min(ts, start)
            this.options.series[0].data.push([ts, o, h, l, c])
            if (maxDay) {
              this.options.series[1].data.push([ts, maxDay || h, maxWeek || h])
              this.options.series[2].data.push([ts, minWeek || l, minDay || l])
            }
          }
        )
      timestamp = timestamp - (timestamp % ONE_HOUR)

      // Append blank data
      Array(6)
        .fill()
        .forEach(() => {
          timestamp += ONE_HOUR
          this.options.series[1].data.push([
            timestamp,
            this.maxDay,
            this.maxWeek,
          ])
          this.options.series[2].data.push([
            timestamp,
            this.minWeek,
            this.minDay,
          ])
        })

      const plotBands = []
      // Bands
      Array(10)
        .fill()
        .forEach(() => {
          _pushBand(plotBands, jp)
          jp -= ONE_DAY
        })
      this.options.xAxis.plotBands = plotBands.filter((el) => {
        return el.to > start && el.from < timestamp
      })
    },
  },
}

function _pushBand(plotBands, jp) {
  plotBands.push(
    {
      color: '#e1ebf5',
      from: jp,
      to: jp + SPAN,
    },
    {
      color: '#faf2e3',
      from: jp + SPAN,
      to: jp + 2 * SPAN,
    },
    {
      color: '#f5e7e4',
      from: jp + 2 * SPAN,
      to: jp + 3 * SPAN,
    }
  )
}
</script>
